import React from 'react';

import PropTypes from 'prop-types';
import {
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
  FaReddit,
  FaFacebook
} from 'react-icons/fa';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton
} from 'react-share';
import './index.scss';

const SocialShare = ({ url }) => (
  <div className="social-share-buttons">
    <LinkedinShareButton url={url}>
      <FaLinkedinIn size="1.8rem" id="linkedin-icon" />
    </LinkedinShareButton>
    <FacebookShareButton url={url}>
      <FaFacebook size="1.8rem" id="facebook-icon" />
    </FacebookShareButton>
    <TwitterShareButton url={url}>
      <FaTwitter size="1.8rem" id="twitter-icon" />
    </TwitterShareButton>
    <WhatsappShareButton url={url}>
      <FaWhatsapp size="1.8rem" id="whatsapp-icon" />
    </WhatsappShareButton>
    <RedditShareButton url={url}>
      <FaReddit size="1.8rem" id="reddit-icon" />
    </RedditShareButton>
    {/* Instagram does not support sharing via web */}
  </div>
);

SocialShare.propTypes = {
  url: PropTypes.string.isRequired
};

export default SocialShare;
