import React from 'react';

import './index.scss';

const BlogHeader = () => {
  return (
    <>
      <div className="blog-header">
        <h2>Sequin Blog</h2>
      </div>
    </>
  );
};

export default BlogHeader;
