import React from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import BlogHeader from '../../components/Blog/BlogHeader/index';
import OpinionDisclaimer from '../../components/OpinionDisclaimer';
import SocialShare from '../../components/SocialShare';
import { handleDate } from '../../utils/time';
import './blog.scss';
import './blogPost.scss';

const BlogPost = ({ data }) => {
  const post = data.allContentfulBlogPost.nodes[0] || {};
  const content = post.body?.childMarkdownRemark?.html || '';

  const seoTitle = post?.seoTitle || post.title;
  const seoDescription = post?.seoDescription || '';

  const getFirstSubjectTag = subjectTag => {
    if (!subjectTag) return '';
    if (Array.isArray(subjectTag)) return subjectTag[0];
    return subjectTag.split(',')[0];
  };

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content={post.heroImage?.url || ''} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.sequincard.com/blog${post.slug}`}/>
      </Helmet>
      <BlogHeader/>
      <div className="blog-post">
        <SocialShare
          url={`https://www.sequincard.com/blog${
            post.slug || 'https://www.sequincard.com/blog'
          }`}
        />
        <div className="post-container">
          <div className="header">
            <p className="subject-tag">{getFirstSubjectTag(post.subjectTag)}</p>
            <h1 className="blog-title">{post.title || ''}</h1>
            <p className="blog-date">{handleDate(post.publishDate) || ''}</p>
            {post.author && (
              <div className="author-container">
                {post.author.image?.url && (
                  <img
                    src={post.author.image.url}
                    alt={post.author.image.title}
                    className="author-image"
                  />
                )}
                <div className="author-info">
                  <p className="author-name">{post.author.name}</p>
                  <p className="author-role">{post.author.shortDescription}</p>
                </div>
              </div>
            )}
          </div>

          <div
            className="blog-text"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <OpinionDisclaimer />
        </div>
      </div>
    </>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          slug: PropTypes.string,
          publishDate: PropTypes.string,
          tags: PropTypes.arrayOf(PropTypes.string),
          body: PropTypes.shape({
            body: PropTypes.string,
            internal: PropTypes.shape({
              content: PropTypes.string
            }),
            childMarkdownRemark: PropTypes.shape({
              html: PropTypes.string
            })
          }),
          heroImage: PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string
          })
        })
      )
    })
  })
};

export default BlogPost;

export const query = graphql`
  query blogQuery($slug: String) {
    allContentfulBlogPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        heroImage {
          url
          title
        }
        publishDate
        readTime
        author {
          name
          shortDescription
          image {
            url
            title
          }
        }
        subjectTag
        body {
          body
          internal {
            content
          }
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
