import './index.scss';
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const OpinionDisclaimer = () => {
  const data = useStaticQuery(graphql`
    query finePrintQuery {
      allContentfulFinePrint(filter: { slug: { eq: "opinion-disclaimer" } }) {
        nodes {
          header
          subheader
          body {
            raw
          }
          slug
        }
      }
    }
  `);

  let disclaimer = data.allContentfulFinePrint.nodes[0];

  return (
    <div className="opinion-disclaimer">
      <h3>{disclaimer.header}</h3>
      <p>{renderRichText(disclaimer.body)}</p>
    </div>
  );
};

export default OpinionDisclaimer;
